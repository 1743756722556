import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import bg2 from 'assets/img/generic/bg-2.jpg';
import Section from 'components/common/Section';
import {useNavigate} from "react-router-dom";

const Cta = () => {

  let navigate = useNavigate()

  const handleClick = ()=>{

    navigate("/auth/register")

  }
  return (
  <Section overlay image={bg2} position="center top" className="light bg-dark">
    <Row className="justify-content-center text-center">
      <Col lg={8}>
        <p className="fs-3 fs-sm-4 text-white">
          Join the ProxyFlicker network and take your bot deployment to the next level with our stable and reliable
          tools. Enhance your projects with the benefits of our network!
        </p>
        <Button
            variant="outline-light"
            size="lg"
            className="border-2 rounded-pill mt-4 fs-0 py-2"
            onClick={handleClick}
        >
          Accelerate your bot's success today
        </Button>
      </Col>
    </Row>
  </Section>
  )
}

export default Cta;
