import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Link } from 'react-router-dom';
import CartItem from './CartItem';
import CartModal from './CartModal';
import { ProductContext } from 'context/Context';
import { getSubtotal } from 'helpers/utils';
import PageHeader from "../../../common/PageHeader";
import axios from "axios";
import {v4 } from "uuid"
import {useDispatch, useSelector} from "react-redux";
import IconAlert from "../../../common/IconAlert";

const ShoppingCart = () => {
  let [cartItems, setCartItems] = useState([])
  let [checkoutError,setCheckoutError] = useState({
    "message":""
  })
  let [disableCheckout,setDisableCheckout] = useState(false)
  let price = useSelector(state=>{
    return state.PriceReducer
  })
  let dispatch = useDispatch()

  let formatter = new Intl.NumberFormat("en-us",{
    "style":"currency",
    currency:"USD"
  })

  useEffect( ()=>{

    axios("https://backend.proxyflicker.net/cart/my-cart",{
      "method":"POST",
      data:{
        "token":window.localStorage.getItem("proxyflicker_authtoken")
      }
    }).then( (resp)=>{
      if ( resp.data.type === "success"){
        let fetchedCartItems = []
        for ( let i = 0 ;i  < resp.data.cart.length;i++){
          if ( resp.data.cart[i] === "NEW"){
            console.log("Added")
            fetchedCartItems.push({
              "modemType":"NEW",
              "description":"NEW LTE ProxyFlicker Modem Proxy",
              "id":v4(),
              "months":1,
              index:i
            })
          }
        }
        setCartItems(fetchedCartItems)
        dispatch({
          type:"set_price",
          price:resp.data.price
        })

      }
    })

  },[])

  let monthTotal = 0;
  for ( let i = 0 ; i < cartItems.length; i++){
    monthTotal += cartItems[i].months
  }

  const handleCheckoutClicked = ()=>{
    setDisableCheckout(true)

    axios("https://backend.proxyflicker.net/cart/checkout",{
      method:"post",
      data:{
        token:window.localStorage.getItem("proxyflicker_authtoken"),
        cartItems
      }
    }).then( (resp)=>{
      if ( resp.data.type === "success"){
        window.location.href = resp.data.coinbaseURL
      }else{
        setCheckoutError({
          message: resp.data.message
        })
        setDisableCheckout(false)
      }
    })
  }

  return (
    <>
      <PageHeader
          preTitle="Finalize Your order"
          title="ProxyFlicker Modem Proxy Checkout"
          description="Check your shopping cart content and checkout securely. "
          className="mb-3"
          titleTag="h2"
      >
      </PageHeader>
      <Card>
        {
          checkoutError.message && (<IconAlert variant={"danger"} dismissible={true}>{checkoutError.message}</IconAlert>)
        }
          <Card.Header>
          <Row className="justify-content-between">
            <Col md="auto">
              <h5 className="mb-3 mb-md-0">
                Shopping Cart ({cartItems.length} Items)
              </h5>
            </Col>
            <Col md="auto">
              <IconButton
                className="border-300 me-2"
                iconClassName="me-1"
                variant="outline-secondary"
                size="sm"
                icon="chevron-left"
                transform="shrink-4"
                as={Link}
                to="/app/purchase"
              >
                Continue Shopping
              </IconButton>
              <Button
                variant="primary"
                size="sm"
                disabled={disableCheckout}
              >
                Coinbase Checkout
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          {cartItems.length > 0 ? (
            <>
              <Row className="gx-card mx-0 bg-200 text-900 fs--1 fw-semi-bold">
                <Col xs={9} md={8} className="py-2">
                  Name
                </Col>
                <Col xs={3} md={4}>
                  <Row>
                    <Col md={8} className="py-2 d-none d-md-block text-center">
                      Months
                    </Col>
                    <Col xs={12} md={4} className="text-end py-2">
                      Price
                    </Col>
                  </Row>
                </Col>
              </Row>
              {cartItems.map(product => (
                <CartItem
                    key={product.id}
                    description={product.description}
                    months={product.months}
                    cartItems={cartItems}
                    setCartItems={setCartItems}
                    index={product.index}
                    modemType={product.modemType}

                />
              ))}
              <Row className="fw-bold gx-card mx-0">
                <Col xs={9} md={8} className="py-2 text-end text-900">
                  Discount
                </Col>
                <Col className="px-0">
                  <Row className="gx-card mx-0">
                    <Col md={7} className="py-2 d-none d-md-block text-center">

                    </Col>
                    <Col
                        xs={12}
                        md={5}
                        className="text-end py-2 text-nowrap px-card"
                    >
                      {  formatter.format((price* (monthTotal-1) )*.05)  }

                    </Col>
                  </Row>
                </Col>
                <Col xs={9} md={8} className="py-2 text-end text-900">
                  Total
                </Col>
                <Col className="px-0">
                  <Row className="gx-card mx-0">
                    <Col md={7} className="py-2 d-none d-md-block text-center">
                    </Col>
                    <Col
                      xs={12}
                      md={5}
                      className="text-end py-2 text-nowrap px-card"
                    >
                      {  formatter.format((price* monthTotal) - ( (price* (monthTotal-1) )*.05 )) }
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <p className="p-card mb-0 bg-light">
              You have no items in your shopping cart. Go ahead and start
              shopping!
            </p>
          )}
        </Card.Body>

        {cartItems.length > 0 && (
          <Card.Footer className="bg-light d-flex justify-content-end">
            <Button
              variant="primary"
              size="sm"
              onClick={handleCheckoutClicked}
              disabled={disableCheckout}
            >
              Coinbase Checkout
            </Button>
          </Card.Footer>
        )}
      </Card>
      <CartModal />
    </>
  );
};

export default ShoppingCart;
