import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {Alert, Button, Col, Form, Row} from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from "axios";
import {useNavigate} from "react-router-dom";
import IconAlert from "../common/IconAlert";

const LoginForm = ({ hasLabel, layout }) => {

  let navigate = useNavigate()

  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  let [disableLogin, setDisableLogin] = useState(false)
  let [alert,setAlert] = useState({
    "type":null,
    "message":null
  })
  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    setDisableLogin(true)
    axios("https://backend.proxyflicker.net/user/login",{
      method:"POST",
      data:{
        email:formData.email,
        password:formData.password
      }
    }).then( (resp)=>{
      let response = resp.data
      let type = "success"
      let message = "Successfully logged in!! Please wait to be redirected "
      if ( response.type === "error"){
        type = "danger"
        message = `${response.message}`
        setDisableLogin(false)
      }else{
        window.localStorage.setItem("proxyflicker_authtoken",response.token)
        setTimeout( ()=>{
          navigate("/app/purchase")
        },2000)

      }
      setAlert({
        type,
        message
      })

    })
    toast.success(`Logged in as ${formData.email}`, {
      theme: 'colored'
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      {alert.type && <IconAlert variant={alert.type} dismissible={true}>{alert.message}</IconAlert> }
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>
      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password || disableLogin}
        >
          Log in
        </Button>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
