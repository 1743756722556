import illustration1 from 'assets/img/icons/spot-illustrations/5.png';
import illustration2 from 'assets/img/icons/spot-illustrations/49.png';
import illustration3 from 'assets/img/icons/spot-illustrations/45.png';

export default [
  {
    icon: ['far', 'lightbulb'],
    iconText: 'PLOT',
    color: 'danger',
    title: 'Prepare & Brace',
    description:
      'ProxyFlicker provides the necessary tools and resources to turn your acquisition goals into a reality.',
    image: illustration1
  },
  {
    icon: ['far', 'object-ungroup'],
    iconText: 'DEPLOY',
    color: 'info',
    title: 'The ability to utilize millions of IP addresses',
    description:
      "ProxyFlicker's advanced proxy engine, specifically designed for IoT drones, allows for the seamless and reliable deployment of bots using specialized hardware and software..",
    image: illustration3,
    inverse: true
  },
  {
    icon: ['far', 'paper-plane'],
    iconText: 'RECEIVE',
    color: 'success',
    title: 'Receive and retrieve',
    description:
      'ProxyFlicker is constantly tested and verified to ensure reliable results for your bot, regardless of its intended use (e.g. Nike Bots, OnlyFan Bots).',
    image: illustration2
  }
];
