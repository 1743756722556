export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
  ]
};
export const appRoutes = {
  label: 'Proxy Flicker',
  children: [

    {
      name: 'Modem Manager',
      icon: 'comments',
      to: '/app/modemmanager',
      active: true
    },
    {
      name: 'Change Proxy Auth',
      icon: 'key',
      to: '/app/change-proxy-auth',
      active: true
    },

  ]
};

export const pagesRoutes = {
  label: 'Commerce',
  children: [
    {
      name: 'Purchase',
      icon: 'money',
      to: '/app/purchase',
      active: true
    },
    {

      name: 'Past orders',
      icon: 'tags',
      to: '/app/invoices',
      active: true
    },
    {
      name: 'Shopping Cart',
      icon: 'shopping-cart',
      to: '/app/shopping-cart',
      active: true
    }
  ]
};

export const modulesRoutes = {
  label: 'Documentation',
  children: [
    {
      name: 'API Documenation',
      icon: ['fab', 'trello'],
      to: '/app/api-docs',
      active: true
    }

  ]
};

export const documentationRoutes = {
  label: 'Account Management',
  children: [

    {
      name: 'Change Password',
      icon: 'lock',
      to: '/app/change-password',
      active: true
    },

    {
      name: 'Logout',
      icon: 'logout',
      to: '/auth/logout',
      active: true
    },
  ]
};

export default [
  dashboardRoutes,
  appRoutes,
  pagesRoutes,
  modulesRoutes,
  documentationRoutes
];
