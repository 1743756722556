import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import axios from "axios";
import IconAlert from "../../../common/IconAlert";
const ChangePassword = () => {
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [alert,setAlert] = useState({
    "variant":"",
    "message":""
  })
  const [disableButton,setDisableButton] = useState(false)

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setDisableButton(true)

    axios("https://backend.proxyflicker.net/userpanel/change-password",{
      method:"post",
      data:{
        token:window.localStorage.getItem("proxyflicker_authtoken"),
        oldPassword:formData.oldPassword,
        newPassword:formData.newPassword,
        confirmPassword:formData.confirmPassword
      }
    }).then( (r)=>{
      if ( r.data.type === "success"){
        setAlert({
          "variant":"success",
          "message":r.data.message
        })
      }else{
        setAlert({
          "variant":"danger",
          "message":r.data.message
        })
      }
      setDisableButton(false)
    })
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Change Password" />
      <Card.Body className="bg-light">
        { alert.message && <IconAlert variant={alert.variant} dismissible={true}>
          {alert.message}
        </IconAlert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="oldPassword">
            <Form.Label>Old Password</Form.Label>
            <Form.Control
              type="password"
              value={formData.oldPassword}
              name="oldPassword"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="newPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              value={formData.newPassword}
              name="newPassword"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="confirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              value={formData.confirmPassword}
              name="confirmPassword"
              onChange={handleChange}
            />
          </Form.Group>
          <Button className="w-100" type="submit" disabled={disableButton}>
            Update Password
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ChangePassword;
