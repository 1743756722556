import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import axios from "axios";
const ChangeAuthForm = () => {
    // State
    const [proxyKey, setProxyKey] = useState('');
    const [disableButton,setDisableButton] = useState(true)
    // Handler
    const handleSubmit = e => {
        e.preventDefault();
        setDisableButton(true)

        axios("https://backend.proxyflicker.net/modem/resetauthid",{
            method:"POST",
            data:{
                token:window.localStorage.getItem("proxyflicker_authtoken")
            }
        }).then( (resp)=>{
            if ( resp.data.type==="success"){
                setProxyKey(resp.data.modemAuthID)
                setDisableButton(false)

            }

        })

    };

    useEffect( ()=>{
        axios("https://backend.proxyflicker.net/modem/mymodemauth",{
            method:"POST",
            data:{
                token:window.localStorage.getItem("proxyflicker_authtoken")
            }
        }).then( (resp)=>{
            console.log(resp.data)
            if ( resp.data.type === "success"){
                setProxyKey(resp.data.modemAuthID)
                setDisableButton(false)
            }
        })

    },[])

    return (
        <Form className="mt-4" onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
                <Form.Control
                    placeholder={'Current Proxy Password'}
                    value={proxyKey}
                    disabled={true}
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Button className="w-100" type="submit" disabled={disableButton}>
                    Update Key
                </Button>
            </Form.Group>


        </Form>
    );
};



export default ChangeAuthForm;
