import PageHeader from "../../components/common/PageHeader";
import {Col, Row} from "react-bootstrap";
import FalconComponentCard from "../../components/common/FalconComponentCard";
import ChangePassword  from "../../components/pages/user/settings/ChangePassword"
const ChangeUserPassword = ()=>{

    return (
        <>
        <PageHeader
            title="Change Login Password"
            description="Update your login password"
            className="mb-3"
        >

        </PageHeader>
            <Row>
                <Col>
                    <ChangePassword />
                </Col>
            </Row>
        </>
    )

}
export default ChangeUserPassword