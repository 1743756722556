import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Button, Form, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';

const QuantityController = (props) => {



    const handleMinus = ()=>{
        if ( props.selectedQuantity === 1 ){
            return
        }
        props.setSelectedQuantity( ()=>{
            return props.selectedQuantity-1
        })
    }
    const handlePlus = ()=>{
        if ( props.selectedQuantity === props.quantityCount){
            return
        }
        props.setSelectedQuantity( ()=>{
            return props.selectedQuantity +1
        })


    }
  return (
    <InputGroup
        size="sm"
    >
      <Button
        variant="outline-secondary"
        size="sm"
        onClick={handleMinus}
        disabled={props.quantityCount <= 0}
      >
        -
      </Button>
      <Form.Control
        className="text-center px-2 input-spin-none"
        type="number"
        value={props.selectedQuantity}
        style={{ width: '50px' }}
        disabled={props.quantityCount <= 0}


      />
      <Button
        variant="outline-secondary"
        size="sm"
        onClick={handlePlus}
        disabled={props.quantityCount <= 0}

      >
        +
      </Button>
    </InputGroup>
  );
};


export default QuantityController;
