import React, { useEffect, useState } from 'react';
import { Badge, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import modemImg from "assets/FlickerImages/logos/1.png"
const ProductDetailsMedia = () => {


  return (
    <div className="position-relative h-sm-100 overflow-hidden">
        <Image
          fluid
          className="fit-cover w-sm-100 h-sm-100 rounded"
          src={modemImg}
          alt="product media"
        />
    </div>
  );
};

ProductDetailsMedia.propTypes = {
  product: PropTypes.shape({
    files: PropTypes.arrayOf(PropTypes.object),
    isNew: PropTypes.bool
  })
};

export default ProductDetailsMedia;
