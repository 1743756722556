import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import {Provider} from "react-redux"
import {legacy_createStore as createStore} from "redux";
import allReducers from "./ProxyFlicker/FlickerReducers/AllReducers"

let store = createStore(allReducers)

ReactDOM.render(

    <Provider store={store}>
  <React.StrictMode>
    <Main>
      <App />
    </Main>
  </React.StrictMode>
    </Provider>,
  document.getElementById('main')
);
