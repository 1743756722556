const CartReducer = (state=0,action)=>{
    switch (action.type){
        case "add_modem":
            state++
            return state
        case "remove_modem":
            state--
            return state
        default:
            return state
    }
}
export default CartReducer;