import React, {useContext, useState} from 'react';
import { Navigate, useParams } from 'react-router';
import { Card, Col, Row } from 'react-bootstrap';
import ProductDetailsMedia from './ProductDetailsMedia';
import ProductDetailsMain from './ProductDetailsMain';
import ProductDetailsFooter from './ProductDetailsFooter';
import { ProductContext } from 'context/Context';
import CartModal from '../../cart/CartModal';
import Flex from 'components/common/Flex';
import PageHeader from "../../../../common/PageHeader";

const ProductDetails = () => {

    let [showCartModal,setShowCartModal] = useState(false)
    let [selectedQuantity, setSelectedQuantity] = useState(1)

  return  (
    <>
      <PageHeader
          preTitle="Monthly subscription for modem-based proxies"
          title="Suitable for all your botting needs"
          description="Enhance the performance of your bot with our premium-grade LTE modem proxies. These proxies are ideal for use with sneaker bots, account creation, and more."
          className="mb-3"
          titleTag="h2"
      >
      </PageHeader>

      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col lg={6} className="mb-4 mb-lg-0">
              <ProductDetailsMedia

              />
            </Col>
            <Col lg={6} as={Flex} justifyContent="between" direction="column">
              <ProductDetailsMain
                  selectedQuantity={selectedQuantity}
                  setSelectedQuantity={setSelectedQuantity}
                  setShowModal={setShowCartModal}
                  showModal={showCartModal}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <ProductDetailsFooter

              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <CartModal
          showCartModal={showCartModal}
          setShowCartModal={setShowCartModal}
          selectedQuantity={selectedQuantity}
          setSelectedQuantity={setSelectedQuantity}
      />
    </>
  );
};

export default ProductDetails;
