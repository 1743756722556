import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Button, Form, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';

const MonthController = (props) => {


    const updateQuantity = (id,addOrSubtract)=>{
        let updatingFactor = addOrSubtract === "add" ? 1 : -1
            let updated = []
            for ( let i = 0; i < props.cartItems.length; i++){
                if ( props.cartItems[i].index === props.index){
                    props.cartItems[i].months = props.cartItems[i].months + updatingFactor
                }
                updated.push(props.cartItems[i])
            }
            props.setCartItems(updated)


    }
    const handleMinus = ()=>{
        if ( props.months <= 1){
            return
        }
        updateQuantity(props.index,"subtract")
    }
    const handlePlus = ()=>{
        updateQuantity(props.index,"add")
    }
    return (
        <InputGroup
            size="sm"
        >
            <Button
                variant="outline-secondary"
                size="sm"
                onClick={handleMinus}
            >
                -
            </Button>
            <Form.Control
                className="text-center px-2 input-spin-none"
                type="number"
                value={props.months}
                style={{ width: '50px' }}
            />
            <Button
                variant="outline-secondary"
                size="sm"
                onClick={handlePlus}
            >
                +
            </Button>
        </InputGroup>
    );
};


export default MonthController;
