import PageHeader from "../../components/common/PageHeader";
import {Accordion, Col, Row} from "react-bootstrap";
import FalconComponentCard from "../../components/common/FalconComponentCard";

const Documentation = ()=>{

    return (
        <>
        <PageHeader
            title="API Documentation"
            description="View our API documentation. "
            className="mb-3"
        >
        </PageHeader>
            <Row>
                <Col>
                    <FalconComponentCard>
                        <FalconComponentCard.Body>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>REST Documentation</Accordion.Header>
                                    <Accordion.Body>
                                        <a href={"https://backend.proxyflicker.net/api-docs/"} target={"_blank"}>
                                        Click here to be redirected to our RESTFUL API documentation made in accordance to OpenAPI 3.0.0 served with Swagger
                                            <br/><br/>
                                        Alternatively you can go to </a> :<br/><br/>https://backend.proxyflicker.net/api-docs/
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Node JS SDK</Accordion.Header>
                                    <Accordion.Body>
                                        <a href={"https://www.npmjs.com/package/proxyflickerclient"} target={"_blank"}>
                                            Click here to be redirected to our NodeJS SDK hosted on npm with detailed instructions
                                            <br/><br/>
                                            Alternatively you can go to </a> :<br/><br/>https://www.npmjs.com/package/proxyflickerclient
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </FalconComponentCard.Body>
                    </FalconComponentCard>
                </Col>
            </Row>
            </>

)

}

export default Documentation;