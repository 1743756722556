import {Button} from "react-bootstrap";

const RenewalButton = (props)=>{

    const clickHandler = ()=>{

        props.handleOnClick(props.alias)
    }

    return (
        <Button
            variant={props.disableButton ? "danger":"success"}
            disabled={props.disableButton}
            onClick={clickHandler}
        >
            Add time
        </Button>
    )
}
export default RenewalButton;