export default [
  {
    media: { icon: "globe", color: 'info', className: 'fs-4' },
    title: 'ProxyFlicker utilizes a fast and reliable LTE network.',
    description:
        "Utilize the top-quality network provided by ProxyFlicker to deploy your bot from any location."
  },
  {
    media: { icon: "bot", color: 'success', className: 'fs-5' },
    title: 'Constantly Tested',
    description:
        'As a ProxyFlicker customer, you can trust that both the network and software are constantly maintained and updated to ensure compatibility and performance with any bot you choose to use.'
  },
  {
    media: { icon: "bitcoin", color: 'warning', className: 'fs-6' },
    title: 'API Access',
    description:
        'Our developer-focused documentation provides clear instructions on how to use our APIs and SDKs, including examples and guidance on error messages and authentication. We strive to keep the documentation concise and up to date.'
  }
];
