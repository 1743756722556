import bw1 from 'assets/img/logos/b&w/6.png';
import bw2 from 'assets/img/logos/nike.png';
import bw3 from 'assets/img/logos/gmail.png';
import bw4 from 'assets/img/logos/facebook.png';
import bw5 from 'assets/img/logos/youtube.png';
import bw6 from 'assets/img/logos/adidas.png';
import bw7 from 'assets/img/logos/onlyfans.png';

export default [
  { src: bw1, height: 40 },
  { src: bw2, height: 60 },
  { src: bw3, height: 30 },
  { src: bw4, height: 30 },
  { src: bw5, height: 35 },
  { src: bw6, height: 40 },
  { src: bw7, height: 40 }
];
