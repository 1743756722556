import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ProductContext } from 'context/Context';
import { getProductsQuantity } from 'helpers/utils';
import React, {useContext, useEffect} from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";

const CartNotification = () => {
  const {
    productsState: { cartItems }
  } = useContext(ProductContext);
  let cartStore = useSelector( selector=>selector.CartReducer)
    let dispatch = useDispatch()
  useEffect ( ()=>{
      axios("https://backend.proxyflicker.net/cart/my-cart",{
          method:"POST",
          data:{
              token:window.localStorage.getItem("proxyflicker_authtoken")
          }
      }).then( (r)=>{
          if ( r.data.type === "success" && !cartStore){
              console.log(r.data.cart)
              for ( let i = 0; i < r.data.cart.length; i++){
                  dispatch({
                          "type":"add_modem"
                      })
              }
          }
      })

  },[])
    let navClass = "px-0 " + (cartStore ? 'notification-indicator notification-indicator-warning position-relative notification-indicator-fill' : '')

  return (
    <Nav.Item as="li" className="d-none d-sm-block">
      <Nav.Link
        as={Link}
        to="/app/shopping-cart"
        className= {navClass}

      >
        {cartStore > 0 && (
          <span className="notification-indicator-number">
            {cartStore}
          </span>
        )}
        <FontAwesomeIcon
          icon="shopping-cart"
          transform="shrink-7"
          className="fs-4"
        />
      </Nav.Link>
    </Nav.Item>
  );
};

export default CartNotification;
