import React, { useContext, useEffect } from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import AppContext from 'context/Context';
import classNames from 'classnames';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import Footer from 'components/footer/Footer';
import ProductProvider from 'components/app/e-commerce/ProductProvider';
import CourseProvider from 'components/app/e-learning/CourseProvider';
import {Routes, Route } from "react-router-dom"
import Purchase from "../ProxyFlicker/Purchase/Purchase";
import Landing from "../components/pages/landing/Landing";
import ModemManager from "../ProxyFlicker/ModemManager/ModemManager";
import axios from "axios";
import Invoices from "../ProxyFlicker/Invoices/Invoices";
import Orders from "../components/app/e-commerce/orders/order-list/Orders";
import OthersWidgets from "../widgets/OthersWidgets";
import ChangeProxyAuth from "../ProxyFlicker/ChangeProxyAuth/ChangeProxyAuth";
import Profile from "../components/pages/user/profile/Profile";
import ChangeUserPassword from "../ProxyFlicker/ChangePassword/ChangeUserPassword";
import ProductDetails from "../components/app/e-commerce/product/product-details/ProductDetails";
import ShoppingCart from "../components/app/e-commerce/cart/ShoppingCart";
import Documentation from "../ProxyFlicker/Documentation/Documentation";
const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  // const isChat = pathname.includes('chat');
  let navigate = useNavigate()


  useEffect( ()=>{
    let token = window.localStorage.getItem("proxyflicker_authtoken")
    axios("https://backend.proxyflicker.net/modem/mymodems",{
      method:"POST",
      data:{
        token:token
      }
    }).then( (r)=>{
      console.log("Passed auth")
      let response = r.data
      if ( response.type === "error"){
        navigate("/auth/login")
      }
    })
  },[])
  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
      <>
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical />
      )}
      <ProductProvider>
        <CourseProvider>
          <div className={classNames('content', { 'pb-0': isKanban })}>
            <NavbarTop />
            <Routes>
              <Route path="/purchase" element={<ProductDetails/>} />
              <Route path="/modemmanager" element={<ModemManager/>} />
              <Route path="/api-docs" element={<Documentation/>} />
              <Route path="/change-proxy-auth" element={<ChangeProxyAuth />}/>
              <Route path="/invoices" element={<Invoices/>} />
              <Route path="/change-password" element={<ChangeUserPassword />}/>
              <Route path="/shopping-cart" element={<ShoppingCart/>} />
            </Routes>
            <Outlet />
            {!isKanban && <Footer />}
          </div>
        </CourseProvider>
      </ProductProvider>
    </div>

      </>
  );
};

export default MainLayout;
