const PriceReducer = (state=1000,action)=>{

    switch (action.type ){
        case "set_price":
            state = action.price;
            return state
        default:
            return state
    }

}

export default PriceReducer;