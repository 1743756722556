import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useProductHook from '../product/useProductHook';
import { ProductContext } from 'context/Context';
import QuantityController from '../QuantityController';
import proxyFlickerSmallImg from "assets/FlickerImages/logos/1.png"
import MonthController from "../MonthController";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
const CartItem = (props) => {

  let cartDispatch = useDispatch()
  let price = useSelector(states=>{
    return states.PriceReducer;
  })
  let formatter = new Intl.NumberFormat("en-us",{
    style:"currency",
    currency:"USD"
  })
  console.log(props.modemType)
  const removeItem = ()=>{

    axios("https://backend.proxyflicker.net/cart/update-cart",{
      method:"POST",
      data:{
        "token":window.localStorage.getItem("proxyflicker_authtoken"),
        "type":"delete",
        "modemType":props.modemType,
        quantity:1

      }
    }).then( (resp)=>{
      if ( resp.data.type === "success"){
        let updated = []
        for ( let i = 0; i < props.cartItems.length; i++){
          if ( props.cartItems[i].index === props.index){
            continue
          }
          updated.push(props.cartItems[i])
        }
        props.setCartItems(updated)
        cartDispatch({
          type:"remove_modem"
        })
      }
    })

  }

  return (
    <Row className="gx-card mx-0 align-items-center border-bottom border-200">
      <Col xs={8} className="py-3">
        <div className="d-flex align-items-center">
          <Link to="/e-commerce/product/product-details">
            <img
              src={proxyFlickerSmallImg}
              width="60"
              className="img-fluid rounded-1 me-3 d-none d-md-block"
            />
          </Link>
          <div className="flex-1">
            <h5 className="fs-0">
              <Link
                to="/e-commerce/product/product-details"
                className="text-900"
              >
                {props.description}
              </Link>
            </h5>
            <div className="fs--2 fs-md--1">
              <Button
                  onClick={removeItem}
                variant="link"
                size="sm"
                className="text-danger fs--2 fs-md--1 fw-normal p-0"
              >
                Remove
              </Button>
            </div>
          </div>
        </div>
      </Col>
      <Col xs={4} className="py-3">
        <Row className="align-items-center">
          <Col
            md={{ span: 8, order: 0 }}

            xs={{ order: 1 }}
            className="d-flex justify-content-end justify-content-md-center"
          >
            <div>
              <MonthController
                  id={props.key}
                  cartItems={props.cartItems}
                  setCartItems={props.setCartItems}
                  months = {props.months}
                  index={props.index}

              />
            </div>
          </Col>
          <Col
            md={{ span: 4, order: 1 }}
            xs={{ order: 0 }}
            className="text-end ps-0 mb-2 mb-md-0 text-600"
          >
            { formatter.format((price* props.months) - ( (price* (props.months-1) )*.05 )) }
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

CartItem.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    files: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    totalPrice: PropTypes.number.isRequired
  })
};

export default CartItem;
