import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import MainLayout from "./layouts/MainLayout";
import Landing from "./components/pages/landing/Landing";
import AuthSimpleLayout from "./layouts/AuthSimpleLayout";
import SimpleLogin from "./components/authentication/simple/Login"
import SimpleRegister from "./components/authentication/simple/Registration"
import SimpleLogout from './components/authentication/simple/Logout';

import Layout from "./layouts/Layout";
import Forms from "./widgets/Forms";
import {useSelector} from "react-redux";

const App = () => {



  return (
    <Router basename={process.env.PUBLIC_URL}>
        <Routes>
            <Route element={<AuthSimpleLayout/>}>
                <Route path="/auth/login" element={<SimpleLogin/>}/>
                <Route path="/auth/register" element={<SimpleRegister/> }/>
                <Route path="/auth/logout" element={<SimpleLogout/>}/>
            </Route>
            <Route path="/app/*" element={<MainLayout />} />
            <Route path="/" element={<Landing/>} />

        </Routes>
    </Router>
  );
};

export default App;
