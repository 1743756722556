import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bg1 from 'assets/img/generic/bg-1.jpg';
import dashboardDark from 'assets/img/generic/dashboard-alt-dark.png';
import dashboard from 'assets/FlickerImages/FlickModemManager.png'
import Section from 'components/common/Section';
import AppContext from 'context/Context';
import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Typewriter } from 'react-simple-typewriter';
import {useNavigate} from "react-router-dom";

const Hero = () => {

  let navigate = useNavigate()


  const {
    config: { isDark, isRTL }
  } = useContext(AppContext);
  const handleClick = ()=>{

    navigate("/auth/register")

  }
  return (
    <Section
      className="py-0 overflow-hidden light"
      image={bg1}
      position="center bottom"
      overlay
    >
      <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
        <Col
          md={11}
          lg={8}
          xl={4}
          className="pb-7 pb-xl-9 text-center text-xl-start"
        >

          <h1 className="text-white fw-light">
            Bring{' '}
            <span className="fw-bold">
              <Typewriter
                words={['Speed', 'Flexibility', 'Power', 'Edge']}
                loop={false}
                cursor={true}
                cursorStyle="|"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
            <br />
            to your Bots
          </h1>
          <p className="lead text-white opacity-75">
            Leverage the capabilities of ProxyFlicker to streamline your bot development and leave the networking to us.
          </p>
          <Button
            variant="outline-light"
            size="lg"
            className="border-2 rounded-pill mt-4 fs-0 py-2"
            to="#!"
            onClick={handleClick}
          >
            Elevate your bot's potential with the help of ProxyFlicker!
            <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
          </Button>
        </Col>
        <Col
            xl={{ span: 5, offset: 1 }}
            className="align-self-end mt-4 mt-xl-0"
        >
          <Link to="/auth/register" className="img-landing-banner">
            <img
              className="img-fluid"
              src={dashboard}
              alt=""
            />
          </Link>
        </Col>
      </Row>
    </Section>
  );
};

export default Hero;
