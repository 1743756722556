import {Button, Card, Col, Form, Modal, Nav, ProgressBar, Row, Tab, Tabs} from "react-bootstrap";
import IconAlert from "../../../components/common/IconAlert";
import {useEffect, useState} from "react";
import axios from "axios";

const ModemModal = (props)=>{




    let [proxyStatusVariant,setProxyVariant] = useState(()=>{
        return "info"
    })
    let [proxyStatusMessage,setProxyStatusMessage] = useState(()=>{
        return "Please wait while modem info loads"
    })
    let [proxyStatus, setProxyStatus] = useState("")
    let [disableButton,setDisableButton] = useState(true)

    useEffect( ()=>{
        axios("https://backend.proxyflicker.net/api/status",{
            method:"POST",
            data:{
                "modemAuthID":props.showModemModal.modemAuthID,
                "alias":props.showModemModal.alias
            }
        }).then( (resp)=>{

            if ( resp.data.type === "success"){

                let status = resp.data.status
                console.log("status")
                setProxyStatus(status)
                if ( status === "ACTIVE"){
                    console.log("HERE")
                    setProxyVariant("warning")
                    setProxyStatusMessage("It can take up to 30 seconds for modem to reboot")
                    setDisableButton(false)
                    return
                }
                setProxyVariant("danger")
                setProxyStatusMessage("Modem must be in active state")
            }
        })
    },[props.modems])


    const handleResetHard = ()=>{
        setDisableButton(true)
        axios("https://backend.proxyflicker.net/api/reset",{
            method:"POST",
            data:{
                "modemAuthID":props.showModemModal.modemAuthID,
                "alias":props.showModemModal.alias,
                "reset_type":"hard_reset"
            }
        }).then( (resp)=>{
            if ( resp.data.type === "success"){
                setProxyVariant("success")
                setProxyStatusMessage("Successfully sent hard reset, please wait while modem resets")
                return
            }
            setProxyVariant("danger")
            setProxyStatusMessage(resp.data.message)
        })
    }
    const handleResetQuick = ()=>{
        setDisableButton(true)
        axios("https://backend.proxyflicker.net/api/reset",{
            method:"POST",
            data:{
                "modemAuthID":props.showModemModal.modemAuthID,
                "alias":props.showModemModal.alias,
                "reset_type":"quick_reset"
            }
        }).then( (resp)=>{
            if ( resp.data.type === "success"){
                setProxyVariant("success")
                setProxyStatusMessage("Successfully sent quick reset, please wait while modem resets")
                return
            }
            setProxyVariant("danger")
            setProxyStatusMessage(resp.data.message)
        })
    }

    return (
        <Modal
            show={props.showModemModal.show}
            onHide={() => props.setShowModemModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Managing <b>{props.alias}</b></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey="usage" id="uncontrolled-tab-example">
                    <Tab eventKey="usage" title="Usage" className='border-bottom border-x p-3'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col sm={3}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">URL Usage</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Manual Usage</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col sm={9}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <Form.Control type="text" value={`http://${props.alias}:${props.modemAuthID}@23.92.20.127:3002`} readOnly />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <Form>
                                                <Form.Group className="mb-3" controlId="formGroupName">
                                                    <Form.Label>Username</Form.Label>
                                                    <Form.Control type="text" value={props.alias} readOnly/>
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="formGroupName">
                                                    <Form.Label>Password</Form.Label>
                                                    <Form.Control type="text" value={props.modemAuthID} readOnly/>
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="formGroupName">
                                                    <Form.Label>Server</Form.Label>
                                                    <Form.Control type="text" value={"23.92.20.127:3002"} readOnly/>
                                                </Form.Group>
                                            </Form>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Tab>
                    <Tab  eventKey="reset" title="Hard Reset" className='border-bottom border-x p-3'>
                        <div className="d-grid gap-2">
                            <Button onClick={handleResetHard} variant="danger" disabled={proxyStatus !== "ACTIVE" || disableButton}>
                                Trigger reset
                            </Button>
                        </div>
                    </Tab>
                    <Tab  eventKey="quickreset" title="Quick Reset" className='border-bottom border-x p-3'>
                        <IconAlert variant={proxyStatusVariant}>
                            <p className="mb-0">{proxyStatusMessage}</p>
                        </IconAlert>
                        <div className="d-grid gap-2">
                            <Button onClick={handleResetQuick} variant="danger" disabled={proxyStatus !== "ACTIVE" || disableButton}>
                                Trigger reset
                            </Button>
                        </div>
                    </Tab>

                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => props.setShowModemModal(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    )

}
export default ModemModal;