import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col } from 'react-bootstrap';
import Divider from 'components/common/Divider';
import SocialAuthButtons from './SocialAuthButtons';
import axios from "axios";
import IconAlert from "../common/IconAlert";
import {useNavigate} from "react-router-dom";
const RegistrationForm = ({ hasLabel }) => {
    let navigate = useNavigate()
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: ''

  });
  const [alert,setAlert] = useState({
      type:"",
      message:""
  })
  const [disableButton,setDisableButton] = useState(false)


  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    setDisableButton(true)
    axios('https://backend.proxyflicker.net/user/register', {
      method: 'POST',
      data: {
        ...formData
      }
    }).then( (r)=>{
        let response = r.data
        let type = response.type === "success" ? "success" : "danger"
        let message = response.message;
        if ( response.type === "success"){
            setTimeout( ()=>{
                navigate("/auth/login")
            },1000)
        }else{
            setDisableButton(false)
        }
        console.log(type)
        setAlert({
            type,
            message
        })
    })
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };


  return (
    <Form onSubmit={handleSubmit}>
        {alert.type && <IconAlert variant={alert.type} dismissible={true}>
            {alert.message}
        </IconAlert>}
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Password' : ''}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Confirm Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Confirm Password' : ''}
            value={formData.confirmPassword}
            name="confirmPassword"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
      </Row>

      <Form.Group className="mb-4">
        <Button
          className="w-100"
          type="submit"
          disabled={
            !formData.email ||
            !formData.password ||
            !formData.confirmPassword ||
              disableButton
        }
        >
          Register
        </Button>
      </Form.Group>

    </Form>
  );
};

RegistrationForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default RegistrationForm;
