import PageHeader from "../../components/common/PageHeader";
import {Card, Col, Dropdown, Row, Spinner, Table} from "react-bootstrap";
import FalconComponentCard from "../../components/common/FalconComponentCard";
import CardDropdown from "../../components/common/CardDropdown";
import {useEffect, useState} from "react";
import axios from "axios";
import ModemModal from "./ModemModal/ModemModal";
import SoftBadge from "../../components/common/SoftBadge";
import delay from "delay";
import {v4 as uuidv4} from "uuid"
import {io} from "socket.io-client"
const ModemManager = ()=>{

    let [modems,setModems] = useState([])
    let [showModemModal, setShowModemModal] = useState(()=> {
       return  {
           show:false,
           alias:null,
           modemAuthID:null
        }
    })
    const getStatusBadge = (status)=>{
        switch ( status ){
            case "INITIAL LOAD":
                return "warning"
            case "RESET":
                return "danger"
            case "ACTIVE":
                return "success"
            default:
                return "secondary"
        }
    }
    const loadModems = async ()=>{
        await axios("https://backend.proxyflicker.net/modem/mymodems",{
            "method":"POST",
            data:{
                token:window.localStorage.getItem("proxyflicker_authtoken")
            }
        }).then ( (resp)=>{
            if ( resp.data.type === "success"){
                let myModems = resp.data.modems
                modems = myModems
                setModems(()=>{

                    return myModems
                })
                return true
            }

        })
    }
    useEffect(()=>{

        let socket = io("wss://backend.proxyflicker.net/customer",{
            extraHeaders:{
                authorization:window.localStorage.getItem("proxyflicker_authtoken")
            }
        })
        socket.on("get_update",async ()=>{
            console.log("From socket io")
            console.log("Recieved")
            await loadModems()

        })


    },[])

    return (
        <>
            { showModemModal.show &&
                <ModemModal
                showModemModal={showModemModal}
                setShowModemModal={setShowModemModal}
                alias = {showModemModal.alias}
                modemAuthID = {showModemModal.modemAuthID}
                modems={modems}
            />
            }


            <PageHeader
                preTitle="Manage your modems"
                title="Modem Manager GUI"
                description="Easily view, reset, and check the status of your modems"
                className="mb-3"
                titleTag="h2"
            >
            </PageHeader>
            <Row className="mb-3 g-3">
                <Col>
                    <FalconComponentCard noGuttersBottom>
                        <FalconComponentCard.Header
                            title="Modem Table"
                            className="border-bottom"
                            noPreview
                        >
                            <p className="mt-2 mb-0">
                                Navigate this table to manage your modems
                            </p>
                        </FalconComponentCard.Header>
                        <FalconComponentCard.Body>
                            <Table striped responsive>
                                <thead>
                                <tr>
                                    <th scope="col">Proxy</th>
                                    <th scope={"col"}>Status</th>
                                    <th scope="col">Manage</th>
                                </tr>
                                </thead>
                                <tbody>
                                { modems.map( (modem)=>{
                                    return (
                                        <tr key={uuidv4()}>
                                            <td>{modem.alias}</td>
                                            <td>{ <SoftBadge bg={getStatusBadge(modem.status)}>{modem.status}</SoftBadge> }</td>
                                            <td >
                                                <CardDropdown>
                                                    <div className="py-2">
                                                        <Dropdown.Item onClick={ ()=> {setShowModemModal(()=>{
                                                            return {
                                                                show: true,
                                                                alias: modem.alias,
                                                                modemAuthID: modem.modemAuthID
                                                            }
                                                        })} }>Manage</Dropdown.Item>
                                                    </div>
                                                </CardDropdown>
                                            </td>
                                        </tr>
                                    )
                                })}


                                </tbody>
                            </Table>
                        </FalconComponentCard.Body>
                    </FalconComponentCard>
                </Col>

            </Row>


        </>
    )

}

export default ModemManager