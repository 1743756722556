import React from 'react';
import { Tab, Nav, Row, Col, Table, Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import StarRating from 'components/common/StarRating';
import createMarkup from 'helpers/createMarkup';

const ProductDetailsFooter = () => {
  return (
    <div className="overflow-hidden mt-4">
      <Tab.Container defaultActiveKey="description">
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link
              eventKey="description"
              className="ps-0 cursor-pointer outline-none"
            >
              Description
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="specifications"
              className="px-2 px-md-3 cursor-pointer outline-none"
            >
              Specifications
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="description">
            <div
              className="mt-3"
            />
            <b>"ProxyFlicker Modem Proxies utilize the same high-quality hardware found in drones and other Internet of Things (IoT) devices, ensuring reliability and performance.</b>

            <br/>
            <br/>
            Our modem proxies are equipped with premium hardware and optimized code specifically designed for botting purposes. These proxies route traffic through fast, secure LTE networks located in the heart of New York City, surrounded by high levels of human traffic to improve the reliability and performance of your bot. You can trust that your bot will be in good hands with ProxyFlicker.
          </Tab.Pane>
          <Tab.Pane eventKey="specifications">
            <Table className="fs--1 mt-3">
              <tbody>
              <tr className="bg-100" style={{
                "width":"30%"
              }}>
                <td>Instant activation upon purchase </td>
              </tr>
              <tr className="bg-100" style={{
                "width":"30%"
              }}>
                <td>Unlimited IP rotation </td>
              </tr>
              <tr className="bg-100" style={{
                "width":"30%"
              }}>
                <td>Unlimited bandwidth usage</td>
              </tr>
              <tr className="bg-100" style={{
                "width":"30%"
              }}>
                <td>API access included</td>
              </tr>
              <tr className="bg-100" style={{
                "width":"30%"
              }}>
                <td>24/7 Support </td>
              </tr>
              <tr className="bg-100" style={{
                "width":"30%"
              }}>
                <td>ModemManager GUI </td>
              </tr>

              </tbody>
            </Table>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

ProductDetailsFooter.propTypes = {
  product: PropTypes.shape({
    desc: PropTypes.string,
    specification: PropTypes.object,
    reviews: PropTypes.arrayOf(PropTypes.object)
  })
};

export default ProductDetailsFooter;
