import React, {useEffect} from 'react';
import LogoutContent from 'components/authentication/LogoutContent';
import axios from "axios";
const Logout = () => {

    useEffect(() => {
        axios("https://backend.proxyflicker.net/userpanel/logout",{
            method:"post",
            data:{
                token:window.localStorage.getItem("proxyflicker_authtoken")
            }
        }).then( (r)=>{
            console.log(r.data)
        })
    }, [])

    return (
        <>
            <div className="text-center">
                <LogoutContent/>
            </div>
        </>
    )
}
export default Logout;
