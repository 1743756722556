import classNames from 'classnames';
import IconButton from 'components/common/IconButton';
import React, {useContext, useEffect, useState} from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ProductContext } from 'context/Context';
import useProductHook from '../useProductHook';
import StarRating from 'components/common/StarRating';
import QuantityController from '../../QuantityController';
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";

const ProductDetailsMain = (props) => {

    let [quantityCount,setQuantityCount] = useState(0)
    let [addToCart,setAddToCart] = useState(false)
    let [selectedQuantity,setSelectedQuantity] = useState( 1 )
    let formatter = new Intl.NumberFormat("en-us",{
        style:"currency",
        currency:"USD"
    })
    let dispatch = useDispatch()
    let selector = useSelector(r=>r)
    useEffect( ()=>{
        console.log("Called")
        if ( props.showModal === false){
            setSelectedQuantity(1)
        }
        axios("https://backend.proxyflicker.net/purchase/availablemodems",{
            method:"POST",
            data:{
                token:window.localStorage.getItem("proxyflicker_authtoken")
            }
        }).then( (resp)=>{
            if ( resp.data.type === "success"){
                setQuantityCount(resp.data.count)
                dispatch({
                    "type":"set_price",
                    price:resp.data.modemPrice
                })
            }
        })
    },[props.showModal])

    const handleAddToCart = ()=>{

        if ( quantityCount <= 0 ){
            return
        }
        for ( let  i = 0 ; i < selectedQuantity; i++){
            dispatch({
                "type":"add_modem"
            })
        }
        axios("https://backend.proxyflicker.net/cart/update-cart",{
            method:"POST",
            data:{
                "token":window.localStorage.getItem("proxyflicker_authtoken"),
                "type":"add",
                "modemType":"NEW",
                "quantity":selectedQuantity

            }
        }).then( (resp)=>{
            if ( resp.data.type === "success"){
                props.setShowModal(true)
                props.setSelectedQuantity(selectedQuantity)
            }
        })
    }
  return (
    <>
      <h5>ProxyFlicker Modem Proxy</h5>
      <Link to="#!" className="fs--1 mb-2 d-block">
          Universal Botting Proxy

      </Link>
      <p className="fs--1">
          Our multi-purpose botting proxy is suitable for a wide range of use cases, including account creation, views, reviews, and more. We specialize in providing reliable and efficient solutions for all your botting needs.      </p>
      <h4 className="d-flex align-items-center">
        <span className="text-warning me-2">

            {   formatter.format((selector.PriceReducer* selectedQuantity) - ( (selector.PriceReducer* (selectedQuantity-1) )*.05 )) }
        </span>
      </h4>
      <p className="fs--1">
        Stock:{' '}
          { quantityCount > 0 ?
              (
                  <strong className={'text-success'}>Available</strong>
              ) :
              <strong className={'text-danger'}>Cart at max capacity. Please checkout</strong>
          }
      </p>
      <Row>
          <Col xs="auto" className="pe-0">
              <div className="">
                  <QuantityController
                      setQuantityCount={setQuantityCount}
                      quantityCount={quantityCount}
                      selectedQuantity={selectedQuantity}
                      setSelectedQuantity={setSelectedQuantity}
                  />
              </div>
          </Col>
        <Col xs="auto" className="px-2 px-md-3">
          <IconButton
            iconClassName="me-sm-2"
            variant="primary"
            size="sm"
            icon="cart-plus"
            onClick={handleAddToCart}
            disabled={quantityCount <= 0}

          >
            <span className="d-none d-sm-inline-block">Add To Cart</span>
          </IconButton>
        </Col>

      </Row>
    </>
  );
};



export default ProductDetailsMain;
