import PageHeader from "../../components/common/PageHeader";
import {Button, Col, Row, Table} from "react-bootstrap";
import {reactBootstrapDocsUrl} from "../../helpers/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FalconComponentCard from "../../components/common/FalconComponentCard";
import FalconCardHeader from "../../components/common/FalconCardHeader";
import {useEffect, useState} from "react";
import axios from "axios";
import CartModal from "../../components/app/e-commerce/cart/CartModal";
import RenewalButton from "./RenewalButton/RenewalButton";
import {useDispatch} from "react-redux";
import IconAlert from "../../components/common/IconAlert";

const Invoices = ()=>{

    let [pastOrderedModems,setPastOrderedModems] = useState([])
    let [showModal,setShowModal] = useState(false)
    let [alias,setAlias] = useState("")
    const [alert,setAlert] = useState()
    let dispatch = useDispatch()
    const handleOnClick = ( alias)=>{
        console.log("Clicked")
        axios("https://backend.proxyflicker.net/cart/update-cart",{
            method:"POST",
            data:{
                token:window.localStorage.getItem("proxyflicker_authtoken"),
                modemType:alias,
                type:"add",
                quantity:1
            }
        }).then( (r)=>{
            if ( r.data.type === "success"){
                dispatch({
                    "type":"set_price",
                    "price": parseInt(r.data.price)
                })
                if ( !r.data.foundInCart){
                    dispatch({
                        type:"add_modem"
                    })
                }
                setAlias(alias)
                setShowModal(true)
            }
            if ( r.data.type === "error"){
                console.log("error")
                setAlert(r.data.message)
            }
        })
    }
    useEffect( ()=>{

        axios("https://backend.proxyflicker.net/orders/past-orders",{
            method:"POST",
            data:{
                token:window.localStorage.getItem("proxyflicker_authtoken")
            }
        }).then( (resp)=>{
            if ( resp.data.type === "success"){
                setPastOrderedModems(resp.data.modems)
                console.log(resp.data.modems)
            }
        })

    },[])

    return(
        <>
            <PageHeader
                title="Invoices"
                description="View past paid invoices."
                className="mb-3"
            >

            </PageHeader>
            <Row>
                <Col>
                    <FalconComponentCard>
                        <FalconComponentCard.Header
                            title="Invoice Table"
                            className="border-bottom"
                            noPreview
                        >
                            <p className="mt-2 mb-0">
                                Navigate this table to view/renew past services
                            </p>
                        </FalconComponentCard.Header>
                        <FalconComponentCard.Body>
                            {alert && <IconAlert variant={"danger"} dismissible={true}>{alert}</IconAlert>}
                            <Table striped responsive>
                                <thead>
                                    <tr>
                                        <th>Modem Alias</th>
                                        <th>Expiration Date</th>
                                        <th>Renew</th>
                                    </tr>
                                </thead>
                                <tbody>

                                {
                                    pastOrderedModems.length && pastOrderedModems.map( (pastModem)=>{
                                        let dateObj = new Date(pastModem.expirationDate)
                                        let humanDate = `${dateObj.getMonth()+1}/${dateObj.getDate()}/${dateObj.getFullYear()}`
                                    let disableButton = pastModem.isExpired
                                        return (
                                            <tr>
                                                <td>{pastModem.alias}</td>
                                                <td style={{
                                                    color: pastModem.isExpired ? "red" : "green"
                                                }}>{humanDate}</td>

                                                <td >
                                                    <RenewalButton
                                                        disableButton={disableButton}
                                                        handleOnClick={handleOnClick}
                                                        alias={pastModem.alias}
                                                    >
                                                        Add time
                                                    </RenewalButton>
                                                </td>
                                            </tr>
                                        )

                                    })
                                }


                                </tbody>
                            </Table>
                        </FalconComponentCard.Body>
                    </FalconComponentCard>
                </Col>
            </Row>
            <CartModal
                showCartModal={showModal}
                setShowCartModal={setShowModal}
                alias={alias}
                selectedQuantity={1}
                />
        </>
    )
}
export default Invoices