import PageHeader from "../../components/common/PageHeader";
import {Col, Row, Table} from "react-bootstrap";
import FalconComponentCard from "../../components/common/FalconComponentCard";
import ForgetPassword from "../../components/authentication/simple/ForgetPassword";
import ChangeAuth from "./ChangeAuth/ChangeAuth";

const ChangeProxyAuth = ()=>{

    return (
        <>
            <PageHeader
                title="Change Proxy Password"
                description="Instantly update proxy password and persist along current instances"
                className="mb-3"
            >

            </PageHeader>
            <Row>
                <Col>
                    <FalconComponentCard>
                        <FalconComponentCard.Body>
                            <ChangeAuth/>
                        </FalconComponentCard.Body>
                    </FalconComponentCard>
                </Col>
            </Row>
        </>
    )


}
export default ChangeProxyAuth;