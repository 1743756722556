import ForgetPasswordForm from "../../../components/authentication/ForgetPasswordForm";
import ChangeAuthForm from "./ChangeProxyAuthForm/ChangeAuthForm";

const ChangeAuth = ()=>{
    return (
        <div className="text-center">
            <h5 className="mb-0"> Was your proxy key compromised?</h5>
            <small>Quickly and easily reset your proxy and persist it along all network edges</small>
            <ChangeAuthForm />
        </div>
    )

}
export default ChangeAuth