import React, { useContext } from 'react';
import { Col, Modal, Row, Button, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import AppContext, { ProductContext } from 'context/Context';
import proxyFlickerSmallImg from "assets/FlickerImages/logos/1.png"
import {useSelector} from "react-redux";
const CartModal = (props) => {

  let price = useSelector(states=>{
    return states.PriceReducer
  })
  const handleClose = () => {
    props.setShowCartModal(false)
    console.log("Close ")
  };

  let formatter = new Intl.NumberFormat("en-us",{
    style:"currency",
    currency:"USD"
  })


  return (
    <Modal show={props.showCartModal} onHide={handleClose} size="lg">
      <Modal.Header
        closeButton
        closeVariant={'white'}
        className="border-200"
      >
        <Modal.Title as="h5">
          <Flex alignItems="center">
            <div className="icon-item bg-soft-success shadow-none">
              <FontAwesomeIcon icon="cart-plus" className="text-success" />
            </div>
            <div className="ms-2">

              {
                props.alias ?
                    `You added ${props.alias} to cart for renewal` :
                    `You just added ${props.selectedQuantity} modem Proxy`
              }

                </div>
          </Flex>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="g-0 align-items-center">
          <Col>
            <Flex alignItems="center">
              <Link to={`/app/purchase`}>
                <Image
                  src={proxyFlickerSmallImg}
                  rounded
                  className="me-3 d-none d-md-block"
                  width="80"
                  alt="product image"
                />
              </Link>
              <div className="flex-1">
                <h5 className="fs-0">
                  <Link
                    to={`/app/purchase`}
                    className="text-900"
                  >
                    ProxyFlicker Modem Proxy
                  </Link>
                </h5>
              </div>
            </Flex>
          </Col>
          <Col sm="auto" className="ps-sm-3 d-none d-sm-block">
           {  formatter.format((price* props.selectedQuantity) - ( (price* (props.selectedQuantity-1) )*.05  )) }
          </Col>
        </Row>
      </Modal.Body>
        <Modal.Footer className="border-200">
          <Button
            to="/app/purchase"
            as={Link}
            size="sm"
            onClick={handleClose}
            variant="secondary"
          >
            Continue Purchasing
          </Button>
          <IconButton
            as={Link}
            to="/app/shopping-cart"
            size="sm"
            className="ms-2"
            icon="chevron-right"
            variant="primary"
            iconAlign="right"
            onClick={handleClose}
          >
            Go to Cart
          </IconButton>
        </Modal.Footer>
    </Modal>
  );
};

export default CartModal;
